.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

body {
  font-family: "Roboto", sans-serif;
 
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.countriesList{
  width: 100%;
  flex-wrap: wrap;
  position: absolute;
  bottom: 10px;
}
.multCountry{
  position: relative;
}
.filtersDiv{
  position: absolute;
  top: -10px;
  right: 10px;
  transform: translate(0%,50%);
}
.multCountry .apexcharts-legend.apexcharts-align-right.position-top {
  left: auto !important;
  right: 0px !important;
  width: 70% !important;
  display: none;
}
.multCountry h4{
  line-height: 0.5;
  top: -4% !important;
}
.multCountry .chartRef{
  margin-top: 20px;
}
.apexcharts-legend.apexcharts-align-right.position-top {
  display: none;
}
.DashboardColorBoxes {
  min-height: 148px;
  margin: 1px 10px;
}

.DashboardColorBoxes svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.DashboardBoxText div {
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.265);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  padding-top: 2px;
}
.DashboardBoxText h5 {
  color: white;
  margin-top: 5px;
}
.ant-table-thead > tr > th.ant-table-cell {
  color: #2ea3f2;
}

.claimreqdetTab .ant-tabs-nav {
  background-color: white;
  padding: 1rem;
  padding-bottom: 0;
}

.custshadow {
  -webkit-box-shadow: 6px 12px 17px -8px rgba(199, 199, 199, 1);
  -moz-box-shadow: 6px 12px 17px -8px rgba(199, 199, 199, 1);
  box-shadow: 1px 3px 17px -8px rgba(199, 199, 199, 1);
}
.claimDocManSvg svg {
  transform: scale(0.8);
}
.custBorder {
  background-image: repeating-linear-gradient(
      0deg,
      #c8c9ca,
      #c8c9ca 12.74px,
      transparent 14px,
      transparent 23.1px,
      #c8c9ca 24px
    ),
    repeating-linear-gradient(
      90deg,
      #c8c9ca,
      #c8c9ca 12.74px,
      transparent 14px,
      transparent 23.1px,
      #c8c9ca 24px
    ),
    repeating-linear-gradient(
      180deg,
      #c8c9ca,
      #c8c9ca 12.74px,
      transparent 14px,
      transparent 23.1px,
      #c8c9ca 24px
    ),
    repeating-linear-gradient(
      270deg,
      #c8c9ca,
      #c8c9ca 12.74px,
      transparent 14px,
      transparent 23.1px,
      #c8c9ca 24px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
  position: relative;
}

.fileImage img {
  width: 100%;
  height: auto;
}

.countryEditButton .edit-icon {
  height: 25px;
  margin-left: 8px;
}

.forget_password_loginTwo {
  color: #2ea3f2;
}
.forget_password_loginTwo:hover {
  text-decoration: underline;
  cursor: pointer;
}
.steps-container-mob > .step-container > .ant-steps {
  flex-direction: column;
}
@media screen and (max-height: 669px) {
  .footer-container {
    position: static !important;
  }
}
@media screen and (min-height: 736px) and (max-height: 742px) {
  .footer-container {
    position: static !important;
  }
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #000 !important; 
}

